import React, { ReactNode, useState, useCallback } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

export enum DialogTypes {
	OKOnly,
	ContinueCancel,
}

export interface IDialog {
	title: string;
	message: string | ReactNode;
	type: DialogTypes;
	action?: () => void;
}

export const useDialog = () => {
	const [dialog, setDialog] = useState<IDialog | undefined>();

	const showDialog = useCallback((props: IDialog) => {
		setDialog(props);
	}, []);
	const closeDialog = useCallback(() => {
		setDialog(undefined);
	}, []);

	return {
		showDialog,
		closeDialog,
		dialog: dialog && (
			<Dialog
				open={!!dialog}
				onClose={() => {
					setDialog(undefined);
				}}
			>
				<DialogTitle>{dialog.title}</DialogTitle>
				<DialogContent>
					<DialogContentText
						component={
							typeof dialog.message === "string" ? "p" : "div"
						}
					>
						{dialog.message}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setDialog(undefined);
							dialog.action?.call(null);
						}}
						color="primary"
					>
						{dialog.type === DialogTypes.OKOnly ? "Ok" : "Continue"}
					</Button>
					{dialog.type === DialogTypes.ContinueCancel && (
						<Button
							onClick={() => {
								setDialog(undefined);
							}}
							color="secondary"
						>
							{"Cancel"}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		),
	};
};
