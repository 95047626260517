import React from "react";

import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import { Typography } from "~/components/Typography";

import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";

import { customStyles } from "./styles";

import { ISidebarDrawer } from "./types";

const SidebarDrawer: React.FC<ISidebarDrawer> = ({
	onClose = () => null,
	title = "",
	children,
	open,
	className,
}) => {
	const transitionTime = 500;
	const styles = customStyles();

	return (
		<Drawer
			classes={{ paper: styles.paper }}
			className={className}
			anchor="right"
			onClose={onClose}
			open={open}
			transitionDuration={transitionTime}
			disableRestoreFocus
		>
			<Box className={styles.header}>
				<IconButton
					className={styles.icon}
					aria-label="close"
					onClick={onClose}
				>
					<Close />
				</IconButton>
				<Typography variant="h3">{title}</Typography>
			</Box>
			<Divider />
			<Box>{children}</Box>
		</Drawer>
	);
};

export default SidebarDrawer;
