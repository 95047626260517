import React, { useMemo, useState } from "react";

import { emailValidator } from "@autocorp/web-core-utils/lib/emailValidator";

import { capitalize } from "lodash";

import { getErrorMessage } from "@utils/errors";

import { usePeopleContext } from "../../../context";

import { Input } from "~/components/Base/BaseInputs";
import { BaseForm } from "~/components/Base/BaseForm";
import { Button } from "~/components/Base/BaseActions";
import { useFeedbackContext } from "~/components/Feedback";
import { Loading } from "~/components/Loading";

import { MenuItem, Select } from "@material-ui/core";

import { UserRole } from "@api/graphql/types";
import { IAddUserFormProps, IAddUserState } from "./types";

import { customStyles } from "./styles";

type AddUserHandlerKeys = keyof IAddUserState;
type AddUserHandlers = Record<AddUserHandlerKeys, (val: string) => void>;

const AddUserForm: React.FC<IAddUserFormProps> = ({
	readOnly,
	group,
	onComplete,
}) => {
	const { addUser } = usePeopleContext();
	const { handleOpenFeedback } = useFeedbackContext();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [inputValues, updateValue] = useState<IAddUserState>({
		email: "",
		firstName: "",
		lastName: "",
	});

	const styles = customStyles();

	const inputHandler = useMemo<AddUserHandlers>(() => {
		const scopedHandler = (key: AddUserHandlerKeys) => (val: string) => {
			updateValue((state) => ({
				...state,
				[key]: val,
			}));
		};
		return Object.keys(inputValues).reduce(
			(acc, key) => ({
				...acc,
				[key as AddUserHandlerKeys]: scopedHandler(
					key as AddUserHandlerKeys,
				),
			}),
			{} as AddUserHandlers,
		);
	}, [updateValue, inputValues]);

	const [selectedRole, changeRole] = useState<UserRole>(UserRole.Member);

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		changeRole(event.target.value as UserRole);
	};

	const { email, firstName, lastName } = inputValues;

	const handleSubmit = async () => {
		let complete = false;
		if (!loading) {
			if (!emailValidator.test(inputValues.email)) {
				return setError("Please enter a valid email address");
			}
			setLoading(true);
			try {
				await addUser({
					username: email,
					admin: selectedRole === UserRole.Admin,
					groupId: group!.id!,
					firstName,
					lastName,
				});
				complete = true;
				onComplete();
				handleOpenFeedback({
					message: "User added!",
					severity: "success",
				});
			} catch (error) {
				setError(getErrorMessage(error));
			} finally {
				if (!complete) setLoading(false);
			}
		}
	};

	if (!group) {
		return <Loading />;
	}

	return (
		<BaseForm error={error} className={styles.root}>
			<Input
				label="Email"
				type="email"
				update={inputHandler.email}
				id={"input-email"}
				value={inputValues.email}
			/>
			{/* <Input
                label="First Name"
                update={inputHandler.firstName}
                id={"input-firstName"}
                value={inputValues.firstName}
            />
            <Input
                label="Last Name"
                update={inputHandler.lastName}
                id={"input-lastName"}
                value={inputValues.lastName}
            /> */}
			<Select
				id="select-user-role"
				className={styles.roleSelect}
				value={selectedRole}
				onChange={handleChange}
				disabled={readOnly || !group}
				MenuProps={{
					getContentAnchorEl: () => null!,
				}}
			>
				{Object.values(UserRole).map((role) => (
					<MenuItem key={`user-role-${role}`} value={role}>
						{capitalize(role)}
					</MenuItem>
				))}
			</Select>
			<Button
				submit
				label="Add User"
				onClick={handleSubmit}
				loading={loading}
			/>
		</BaseForm>
	);
};

export default AddUserForm;
