import React from "react";

import dayjs from "dayjs";

import { User } from "./context";

const translateLastLogin = (date: string): string => {
	if (!date) return "";
	if (date === "Never") return date;

	return dayjs().to(dayjs(date));
};

interface ILastLoginProps {
	user: User;
}

export const LastLogin: React.FC<ILastLoginProps> = ({ user }) => {
	return <>{translateLastLogin(user?.lastLogin || "")}</>;
};
