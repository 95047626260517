import { makeStyles } from "@material-ui/core";

export const customStyles = makeStyles((theme) => {
	return {
		header: {
			display: "flex",
			gap: 12,
			alignItems: "center",
			padding: 12,
		},
		icon: {
			padding: 0,
		},
		paper: {
			[theme.breakpoints.down(theme.breakpoints.values.xs)]: {
				width: "100%",
			},
		},
	};
});
