import React, { useMemo, useState, useCallback, useRef } from "react";
import debounce from "lodash/debounce";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import { Input } from "~/components/Input";
import { PrimaryButton } from "~/components/Button";
import SidebarDrawer from "~/components/SidebarDrawer";

import { Group, usePeopleContext } from "./context";
import AddUserForm from "./components/forms/AddUserForm";

const useStyles = makeStyles(({ palette, dashboard, breakpoints }) => {
	const inputPadding = "15px";

	return {
		container: {
			display: "flex",
			flexFlow: "row nowrap",
			justifyContent: "space-between",
			padding: "16px",
			[breakpoints.down(breakpoints.values.sm)]: {
				justifyContent: "end",
			},
		},
		inputControl: {
			padding: inputPadding,
			paddingRight: 0,
			width: "100%",
		},
		inputBoxRoot: {
			backgroundColor: "#FDFDFD",
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: "#CACACA",
			},
		},
		inputBoxLabel: {
			color: `${palette.text.primary} !important`,
		},
		searchBox: {
			[breakpoints.down(breakpoints.values.sm)]: {
				display: "none",
			},
		},
	};
});

const InputBox = withStyles((theme) => ({
	root: {
		color: theme.palette.background.paper,
	},
}))(Input);

interface IControlBarProps {
	group?: Group;
	loading: boolean;
}

export const ControlBar: React.FC<IControlBarProps> = ({ group }) => {
	const styles = useStyles();
	const { filterUsers, readOnly } = usePeopleContext();
	const [curFilter, setCurFilter] = useState("");

	const runFilter = useMemo(() => debounce(filterUsers, 500), [filterUsers]);

	const doFilter = useCallback(
		(filter: string) => {
			setCurFilter(filter);
			runFilter({ filter });
		},
		[runFilter],
	);

	const [open, doOpen] = useState(false);

	const handleClose = () => {
		doOpen(false);
	};

	return (
		<Box className={styles.container}>
			<Box className={styles.searchBox}>
				<InputBox
					id="people-list-search"
					inputLabel="Search"
					placeholder="Search"
					variant="outlined"
					fullWidth={false}
					value={curFilter}
					disabled={!group}
					InputLabelProps={{
						className: styles.inputBoxLabel,
						classes: {
							focused: styles.inputBoxLabel,
						},
					}}
					InputProps={{
						color: "secondary",
						classes: {
							input: styles.inputControl,
							root: styles.inputBoxRoot,
						},
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment
								position="end"
								style={{
									visibility: curFilter
										? "visible"
										: "hidden",
								}}
							>
								<IconButton
									size="small"
									onClick={() => {
										doFilter("");
									}}
								>
									<ClearIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
					onChange={(e) => {
						doFilter(e.currentTarget.value);
					}}
				/>
			</Box>
			<PrimaryButton onClick={() => doOpen(true)}>
				Invite User
			</PrimaryButton>
			<SidebarDrawer title="Add User" onClose={handleClose} open={open}>
				<AddUserForm
					group={group}
					readOnly={readOnly}
					onComplete={handleClose}
				/>
			</SidebarDrawer>
		</Box>
	);
};
