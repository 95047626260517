import React from "react";

import { UserStatus } from "~/model/users";
import { ITableColumn } from "~/components/Table";

import { Status } from "./Status";
import { UserMenu } from "./UserMenu";
import { UserRole } from "./UserRole";
import { LastLogin } from "./LastLogin";
import type { User } from "./context";

export const userColumns: ITableColumn[] = [
	// {
	//     label: "Full Name",
	//     property: ["firstName", "lastName"],
	//     width: 100,
	//     cell: (props): string => props.join(" "),
	// },
	{
		label: "",
		property: "status",
		align: "right",
		width: 100,
		cell(props) {
			return <Status status={props[0] as UserStatus} />;
		},
	},
	{
		label: "Email",
		property: "email",
		width: 200,
	},
	{
		label: "Role",
		property: "",
		width: 200,
		cell(props) {
			const user = props[0] as unknown as User;

			return <UserRole user={user} />;
		},
	},
	{
		label: "Last Login",
		property: "",
		width: 200,
		cell(props) {
			const user = props[0] as unknown as User;

			return <LastLogin user={user} />;
		},
	},
	{
		label: "",
		property: "",
		width: 100,
		align: "right",
		cell(props) {
			const user = props[0] as unknown as User;
			return <UserMenu user={user} />;
		},
	},
];
