import React, {
	createContext,
	useContext,
	useState,
	useMemo,
	useCallback,
} from "react";
import isNil from "lodash/isNil";

import { UserGroup, User as UserMeta, UserRole } from "~/model/users";
import { Permissions } from "@graphql/types";
import { useUserQuery } from "@api/users";
import { useAuth } from "@api/auth";
import { useToastContext } from "~/state/toast";

import { mp } from "@utils/mixpanel";
import { getErrorMessage } from "@utils/errors";

import { ITableRow, getEntityRow } from "~/components/Table";
import { userColumns } from "./columns";

export type User = UserMeta & {
	groupId: number;
	loading?: boolean;
};

type Group = Omit<UserGroup, "users"> & {
	users: User[];
};

export type { Group };
export { UserRole };

interface IDisplayGroup {
	group: Group;
	rows: ITableRow[];
}

type DisplayGroupLoading = {
	[K: number]: boolean;
};

type UsersLoading = {
	groups: Map<number, Set<string>>;
	users: Set<string>;
};

interface IPeopleContextActions {
	filterUsers: (options: { filter: string; group?: number }) => void;
	addUser: (options: {
		username: string;
		admin: boolean;
		groupId: number;
		firstName?: string;
		lastName?: string;
	}) => Promise<void>;
	removeUser: (options: { user: User }) => Promise<void>;
	resetPassword: (options: { user: User }) => Promise<string>;
	changeRole: (options: { user: User; newRole: UserRole }) => Promise<void>;
}

interface IPeopleContext extends IPeopleContextActions {
	readOnly: boolean;
	criticalError: string;
	peopleLoading: boolean;
	filter: string;
	groups: Group[];
	groupLoading: DisplayGroupLoading;
	displayGroup?: IDisplayGroup;
}

const PeopleContext = createContext({} as IPeopleContext);

export const usePeopleContext = () => {
	const context = useContext(PeopleContext);
	if (!context) {
		throw new Error(
			"You cannot use the People Context outside of its Provider!",
		);
	}
	return context;
};

const isUserLoading = (
	dict: UsersLoading,
	username: string,
	group?: number,
) => {
	if (dict.users.has(username)) {
		return true;
	}

	if (group) {
		return dict.groups.has(group) && dict.groups.get(group)!.has(username);
	}

	return false;
};

const setUserLoading = (
	dict: UsersLoading,
	username: string,
	loading: boolean,
	group?: number,
	stopEverywhere = false,
): UsersLoading => {
	const newDict = { ...dict };
	if (stopEverywhere && !loading) {
		newDict.users.delete(username);
		newDict.groups.forEach((g) => g.delete(username));
		return newDict;
	}

	if (!group) {
		if (loading) {
			newDict.users.add(username);
		} else {
			newDict.users.delete(username);
		}
		return newDict;
	}

	if (loading) {
		if (!newDict.groups.has(group)) {
			newDict.groups.set(group, new Set());
		}

		newDict.groups.get(group)!.add(username);
	} else {
		if (newDict.groups.has(group)) {
			newDict.groups.get(group)!.delete(username);
		}
	}
	return newDict;
};

interface IProcessGroupsArgs {
	groups: UserGroup[];
	usersLoading: UsersLoading;
	filterBy?: string;
}

const filterFields: (keyof User)[] = ["email", "role", "status"];

const shouldIncludeUser = (filterBy: string, user: User) =>
	Object.entries(user).some(
		([field, value]) =>
			filterFields.includes(field as keyof User) &&
			typeof value !== null &&
			typeof value !== undefined &&
			String(value).toLowerCase().indexOf(filterBy.toLowerCase()) > -1,
	);

const processGroups = ({
	groups,
	usersLoading,
	filterBy,
}: IProcessGroupsArgs): Group[] =>
	groups.map(({ users, ...group }) => {
		const userCollection = users.reduce((userAcc, user) => {
			const procUser = {
				...user,
				groupId: group.id,
			} as User;

			if (filterBy) {
				if (!shouldIncludeUser(filterBy, procUser)) return userAcc;
			}

			procUser.loading = isUserLoading(
				usersLoading,
				user.username,
				group.id!,
			);

			userAcc.push(procUser);
			return userAcc;
		}, [] as User[]);

		return {
			...group,
			users: userCollection,
		};
	});

export const PeopleProvider: React.FC = ({ children }) => {
	const { user, selectedCompany } = useAuth();

	const { addToastError } = useToastContext();

	const [filter, setFilter] = useState("");

	const [groupLoading, setGroupLoading] = useState<DisplayGroupLoading>({});
	const [usersLoading, setUsersLoading] = useState<UsersLoading>({
		users: new Set(),
		groups: new Map(),
	});

	const {
		groups: rawGroups,
		error: criticalError,
		loading,
		userApi,
	} = useUserQuery();

	const readOnly = useMemo(() => {
		if (isNil(selectedCompany?.primaryGroup)) return true;
		const permissions =
			user?.companyPermissions?.[selectedCompany!.primaryGroup]
				?.permissions || [];
		return !permissions.includes(Permissions.ManageUsers);
	}, [selectedCompany, user?.companyPermissions]);

	const groups = useMemo<Group[]>(() => {
		const groups = processGroups({
			groups: rawGroups,
			usersLoading: {
				users: new Set(),
				groups: new Map(),
			},
		});

		groups.forEach((g) => {
			const company = user?.companies.find(
				(c) => c?.primaryGroup === g.id,
			);
			if (company) {
				company.userCount = g.users.length;
			}
		});

		return groups;
	}, [rawGroups, user?.companies]);

	const displayGroup = useMemo(() => {
		const findGroup = (id?: Group["id"]) =>
			!isNil(id) && groups.find((group) => group.id === id);

		const selectedGroup = findGroup(selectedCompany?.primaryGroup);

		const filteredGroup =
			selectedGroup &&
			processGroups({
				groups: [selectedGroup!].filter(Boolean),
				usersLoading,
				filterBy: filter,
			}).pop();

		if (!filteredGroup) return;
		return {
			group: filteredGroup,
			rows: filteredGroup.users
				.sort((a, b) =>
					a.email > b.email ? 1 : a.email < b.email ? -1 : 0,
				)
				.map((user, userIdx) => ({
					id: user.username,
					values: getEntityRow<User>(userColumns, user, userIdx),
				})),
		};
	}, [filter, groups, selectedCompany?.primaryGroup, usersLoading]);

	const startUserLoading = useCallback((username: string, group?: number) => {
		setUsersLoading((dict) => setUserLoading(dict, username, true, group));
	}, []);

	const stopUserLoading = useCallback(
		(username: string, group?: number, stopEverywhere = false) => {
			setUsersLoading((dict) =>
				setUserLoading(dict, username, false, group, stopEverywhere),
			);
		},
		[],
	);

	const peopleContext = useMemo<IPeopleContext>(
		() => ({
			readOnly,
			filter,
			groups,
			displayGroup,
			groupLoading,
			peopleLoading: loading,
			criticalError:
				(criticalError &&
					(getErrorMessage(criticalError) ||
						criticalError?.message)) ||
				"",
			filterUsers: ({ filter }) => {
				setFilter(filter);
				mp.fireEvent({
					event: "searchedUsers",
					context: {
						searchString: filter,
					},
				});
			},
			addUser: async ({
				username,
				admin,
				groupId,
				firstName,
				lastName,
			}) => {
				if (!groupId) {
					addToastError("No group id selected!");
					return;
				}

				try {
					setGroupLoading((cur) => ({
						...cur,
						[groupId]: true,
					}));
					const success = await userApi.addUserToGroup(
						username.trim(),
						groupId,
						admin,
						firstName,
						lastName,
					);

					if (!success) {
						throw "Add user unsuccessful";
					} else {
						mp.fireEvent({
							event: "invitedUser",
							context: {
								affectedUserEmail: username,
								userRole: admin ? "ADMIN" : "MEMBER",
							},
						});
					}
				} catch (err) {
					addToastError(getErrorMessage(err));
				} finally {
					setGroupLoading((cur) => ({
						...cur,
						[groupId]: false,
					}));
				}
			},
			removeUser: async ({ user: { username, groupId } }) => {
				startUserLoading(username);
				try {
					const success = await userApi.revokeGroupAccess(
						username,
						groupId,
					);
					if (!success) {
						throw "Remove user unsuccessful";
					} else {
						mp.fireEvent({
							event: "deletedUser",
							context: {
								affectedUserEmail: username,
							},
						});
					}
				} catch (err) {
					addToastError(getErrorMessage(err));
				} finally {
					stopUserLoading(username);
				}
			},
			resetPassword: async ({
				user: { username, role },
			}): Promise<string> => {
				startUserLoading(username);
				try {
					const [success, temporaryPassword] =
						await userApi.resetPassword(username);
					if (!success) {
						throw "Reset password unsuccessful";
					} else {
						mp.fireEvent({
							event: "resetUserPassword",
							context: {
								affectedUserEmail: username,
								userRole: role,
							},
						});
					}
					return temporaryPassword || "";
				} catch (err) {
					addToastError(getErrorMessage(err));
					return "";
				} finally {
					stopUserLoading(username, undefined, true);
				}
			},
			changeRole: async ({ user, newRole }) => {
				const { username, role } = user;
				if (role === newRole) return;

				startUserLoading(username, user.groupId);

				const callApi = async () => {
					switch (newRole) {
						case UserRole.Admin: {
							return userApi.giveAdminAccess(
								username,
								user.groupId,
							);
						}
						case UserRole.Member: {
							return userApi.revokeAdminAccess(
								username,
								user.groupId,
							);
						}
						default: {
							return addToastError("Invalid user role defined!");
						}
					}
				};

				try {
					const success = await callApi();

					if (!success) {
						throw "Change user role unsuccessful";
					}
				} catch (err) {
					addToastError(getErrorMessage(err));
				} finally {
					stopUserLoading(username, user.groupId);
				}
			},
		}),
		[
			readOnly,
			filter,
			groups,
			displayGroup,
			groupLoading,
			loading,
			userApi,
			criticalError,
			addToastError,
			startUserLoading,
			stopUserLoading,
		],
	);

	return (
		<PeopleContext.Provider value={peopleContext}>
			{children}
		</PeopleContext.Provider>
	);
};
