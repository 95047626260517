import { makeStyles } from "@material-ui/core";

export const customStyles = makeStyles((theme) => ({
	root: {
		padding: "8px 16px",
		boxShadow: "none",
		width: 375,
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			width: "auto",
		},
	},
	roleSelect: {
		width: "100%",
		margin: "16px 8px",
	},
}));
