import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { UserStatus } from "~/model/users";

const commonStyles = {
	borderRadius: 3,
	padding: "2px 8px",
};

const useStyles = makeStyles(() => ({
	pending: {
		...commonStyles,
		border: "1px solid black",
	},
	disabled: {
		...commonStyles,
		border: "1px solid #D32030",
		color: "#D32030",
	},
}));

interface IStatusProps {
	status: UserStatus;
}

const getStatusDisplay = (status: UserStatus) => {
	switch (status) {
		case UserStatus.Disabled: {
			return "Disabled";
		}
		case UserStatus.Pending: {
			return "Pending";
		}
		default: {
			return "";
		}
	}
};

export const Status: React.FC<IStatusProps> = ({ status }) => {
	const styles = useStyles();
	const displayStatus = getStatusDisplay(status);

	const getStatusStyles = (status: UserStatus) => {
		switch (status) {
			case UserStatus.Disabled: {
				return styles.disabled;
			}
			case UserStatus.Pending: {
				return styles.pending;
			}
			default: {
				return "";
			}
		}
	};

	const displayStyles = getStatusStyles(status);

	if (!displayStatus) return null;

	return <Box className={displayStyles}>{displayStatus}</Box>;
};
