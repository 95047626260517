import React, { Fragment, useState, useMemo, useCallback } from "react";
import capitalize from "lodash/capitalize";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { UserRole as UserRoles } from "~/model/users";
import { User, usePeopleContext } from "./context";

const useStyles = makeStyles((theme) => ({
	button: {
		textDecoration: "none",
		textTransform: "none",
		fontWeight: "normal",
		padding: 0,
		borderRadius: 8,
		...theme.typography.body1,
	},
	menu: {
		borderRadius: 8,
	},
}));

interface IUserRoleProps {
	user: User;
}

export const UserRole: React.FC<IUserRoleProps> = ({ user }) => {
	const styles = useStyles();
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const { changeRole, readOnly } = usePeopleContext();

	const handleMenuClick = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorEl(e.currentTarget);
		},
		[],
	);

	const handleClose =
		(cb?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void) =>
		(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			e.preventDefault();
			if (cb) cb(e);
			setAnchorEl(null);
		};

	const RoleButton = useMemo(
		() =>
			readOnly
				? {
						Component: Box,
						props: {
							className: styles.button,
						},
				  }
				: {
						Component: Button,
						props: {
							onClick: handleMenuClick,
							endIcon: <ExpandMore />,
							className: styles.button,
						},
				  },
		[readOnly, handleMenuClick, styles],
	);

	return (
		<Fragment>
			<RoleButton.Component {...RoleButton.props}>
				{capitalize(user.role)}
			</RoleButton.Component>
			<Menu
				keepMounted
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleClose()}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				classes={{
					paper: styles.menu,
				}}
			>
				{Object.values(UserRoles).map((role) => (
					<MenuItem
						key={`change-to-role-${role}`}
						onClick={handleClose(() => {
							changeRole({
								user,
								newRole: role,
							});
						})}
					>
						{capitalize(role)}
					</MenuItem>
				))}
			</Menu>
		</Fragment>
	);
};
