import React, { Fragment, useState, ReactNode } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Progress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import { Typography } from "~/components/Typography";

import { useDialog, DialogTypes } from "@hooks/use-dialog";

import { usePeopleContext, User, UserRole } from "./context";

const useStyles = makeStyles(() => ({
	button: {
		padding: 0,
	},
	menu: {
		borderRadius: 8,
		padding: 0,
	},
}));

interface IUserMenuProps {
	user: User;
}

export const UserMenu: React.FC<IUserMenuProps> = ({ user }) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const { showDialog, dialog } = useDialog();
	const { removeUser, resetPassword, readOnly, addUser } = usePeopleContext();
	const styles = useStyles();

	const { email, loading, status, groupId, role } = user;

	const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(e.currentTarget);
	};

	const handleClose =
		(cb?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void) =>
		(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			e.preventDefault();
			if (cb) cb(e);
			setAnchorEl(null);
		};

	return loading ? (
		<Progress size={27} />
	) : (
		<Fragment>
			<IconButton
				style={{
					visibility: readOnly ? "hidden" : undefined,
				}}
				className={styles.button}
				onClick={handleMenuClick}
			>
				<MoreHorizIcon />
			</IconButton>

			<Menu
				keepMounted
				classes={{
					paper: styles.menu,
				}}
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleClose()}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				{status === "DISABLED" && (
					<MenuItem
						onClick={handleClose(() => {
							showDialog({
								title: "Enable User",
								message: `Are you sure you want to enable ${email}`,
								type: DialogTypes.ContinueCancel,
								action: () => {
									addUser({
										username: email,
										admin: UserRole.Admin === role,
										groupId,
									});
								},
							});
						})}
					>
						<Typography variant="body1">{"Enable"}</Typography>
					</MenuItem>
				)}
				<MenuItem
					onClick={handleClose((e) => {
						showDialog({
							title: "Reset Password",
							message: `Are you sure you want to reset the password for ${email}`,
							type: DialogTypes.ContinueCancel,
							action: async () => {
								const newPass = await resetPassword({
									user,
								});

								// Errors are handled asynchronously using a toast message.
								if (newPass) {
									showDialog({
										title: "New Password",
										message: (
											<Fragment>
												{`The new password for ${email} is:`}
												<p>
													<code>{newPass}</code>
												</p>
											</Fragment>
										),
										type: DialogTypes.OKOnly,
										action: () => null,
									});
								}
							},
						});
					})}
				>
					<Typography variant="body1">{"Reset Password"}</Typography>
				</MenuItem>
				<MenuItem
					onClick={handleClose((e) => {
						showDialog({
							title: "Remove User",
							message: `Are you sure you want to remove ${email}`,
							type: DialogTypes.ContinueCancel,
							action: () => {
								removeUser({
									user,
								});
							},
						});
					})}
				>
					<Typography variant="body1" color="error">
						{"Remove"}
					</Typography>
				</MenuItem>
			</Menu>
			{dialog}
		</Fragment>
	);
};
